import Layout from "components/Layout";
import Collection from "components/Collection";
import SEO from "components/Seo";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";

function CaseStudiesPage() {
  const data = useStaticQuery(graphql`
    query CaseStudiesQuery {
      allContentfulPageCaseStudies {
        edges {
          node {
            pageTitle
            heroSectionHeading
            caseStudyEntries {
              heroSectionHeading
              client
              location
              services
              slug
              thumbnailImage {
                fluid(maxWidth: 800, quality: 90) {
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                }
              }
            }
          }
        }
      }
    }
  `);
  const dataNode = data.allContentfulPageCaseStudies.edges[0].node;
  return (
    <Layout>
      <SEO
        // metaDescription={dataNode.pageDescription}
        title={dataNode.pageTitle}
      />
      <Collection data={dataNode} />
    </Layout>
  );
}

export default CaseStudiesPage;
